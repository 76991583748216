import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPropertyInFire, IPropertyInFireForCsv } from "../../api/models";
import {
    IPropertyInFireParam,
    getPropertyInFire
} from "../../api/smokeCheckAPI";

interface QueryResultState {
    propertiesInFire?: IPropertyInFire[];
    exportData: IPropertyInFireForCsv[];
    propertiesInFireFiltered?: IPropertyInFire[];
}

const initialState: QueryResultState = {
    exportData: [],
}

/** API start */
export const getPropertiesInFireByFireClient = createAsyncThunk("actions/getPropertyInFire", async (param: IPropertyInFireParam) => {
    let response = await getPropertyInFire(param);
    return response || [];
});
/** API end */

export const scQueryResult = createSlice({
    name: "scQueryResult",
    initialState,
    reducers: {
        setPropertiesInFire: (state, action) => {
            state.propertiesInFire = action.payload;
        },
        setPropertiesInFireFiltered: (state, action) => {
            state.propertiesInFireFiltered = action.payload;
        }
    },
    extraReducers: builder =>{
        builder
            .addCase(getPropertiesInFireByFireClient.fulfilled, (state, action) => {
                state.propertiesInFire = action.payload;

                var exportDataArr: IPropertyInFireForCsv[] = [];

                for (const item of action.payload) {
                    exportDataArr.push({
                        policy: item.policy,
                        address1: item.address1,
                        address2: item.address2,
                        city: item.city,
                        state: item.state,
                        propertyType: item.propertyType,
                        enrolled: item.enrolled,
                        threatened: item.threatened,
                        zipCode: item.zipCode,
                        buildingCount: item.buildingCount,
                        producer: item.producer,
                        lineOfBusiness: item.lineOfBusiness,
                        agencyName: item.agencyName,
                        agencyCode: item.agencyCode,
                        agencyPhoneNumber: item.agencyPhoneNumber,
                        location: item.location,
                        lastPropertyStatus: item.lastPropertyStatus,
                        dateAction: item.dateAction,
                        client: item.client,
                        coverageAAmt: item.coverageAAmt,
                        distance: item.distance.toFixed(2)
                    });
                }

                state.exportData = exportDataArr;
            });
    }
});

export const {

    setPropertiesInFire,
    setPropertiesInFireFiltered,
} = scQueryResult.actions;

export default scQueryResult.reducer;
