import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISCPropertyQuery, IPropertyCity, ILob } from "../../api/models";
import {
    IPropertyQueryParam,
    getPropertyQueries,
    savePropertyQuery,
    deletePropertyQuery,
    getPropertyCities,
    IPropertyCityParam,
    getAllLob
} from "../../api/smokeCheckAPI";

export enum BuilderState {
    PreDefinedQuery = 1,
    NewQuery = 2,
    EditQuery = 3
}

export enum PreDefinedList {
    None = 0,
    PotentiallyThreatened = 1,
    UnableToLocateOrAccess = 2
}

interface IPropertyQueryState {
    builderState?: number; // 1: pre-defined query; 2 New query; 3 Edit query
    selectedPreDefined: number; // 0: 1: 2:
    passValidation: boolean;
    customQueries?: ISCPropertyQuery[];
    selectedQuery: ISCPropertyQuery;
    originalQuery: ISCPropertyQuery;
    isChanged: boolean;
    saveButtonText?: string;
    propertyCities?: IPropertyCity[];
    propertyTypes?: ILob[];
    isNameExisted: boolean;
    
}

const initialState: IPropertyQueryState = {
    selectedPreDefined: PreDefinedList.None,
    passValidation: false,
    selectedQuery: { propQueryID: 0, queryName: "", fireId: 0, clientIds: 0, currentThreat: undefined },
    originalQuery: { propQueryID: 0, queryName: "", fireId: 0, clientIds: 0, currentThreat: undefined },
    isChanged: false,
    isNameExisted: false,
}

/** API start */
export const getPropertyQueriesByFire = createAsyncThunk("actions/getPropertyQueries", async (param: IPropertyQueryParam) => {
    let response = await getPropertyQueries(param);
    return response || [];
});

export const postSavePropertyQuery = createAsyncThunk("actions/savePropertyQuery", async (param: ISCPropertyQuery) => {
    let response = await savePropertyQuery(param);
    return response || -2;
});

export const postDeletePropertyQuery = createAsyncThunk("actions/deletePropertyQuery", async (key: number) => {
    let response = await deletePropertyQuery(key);
    return response || -2;
});

export const getPropertyCitiesByFireClient = createAsyncThunk("actions/getPropertyCities", async (param: IPropertyCityParam) => {
    let response = await getPropertyCities(param);
    return response || [];
});

export const getAllPropertyType = createAsyncThunk("actions/getAllPropertyType", async () => {
    let response = await getAllLob();
    return response || [];
});

/** API end */

export const scPropertyQuerySlice = createSlice({
    name: "scPropertyQuery",
    initialState,
    reducers: {
        setBuilderState: (state, action) => {
            state.builderState = action.payload as number;
        },
        setSelectedPreDefined: (state, action) => {
            state.selectedPreDefined = action.payload as number;
        },
        setPassValidation: (state, action) => {
            state.passValidation = action.payload as boolean;
        },
        setSelectedQuery: (state, action) => {
            state.selectedQuery = action.payload;
        },
        setOriginalQuery: (state, action) => {
            state.originalQuery = action.payload;
        },
        setIsChanged: (state, action) => {
            state.isChanged = action.payload;
        },
        setSaveButtonText: (state, action) => {
            state.saveButtonText = action.payload;
        },
        setIsNameExisted: (state, action) => {
            state.isNameExisted = action.payload as boolean;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getPropertyQueriesByFire.fulfilled, (state, action) => {
                state.customQueries = action.payload;
            })
            .addCase(postSavePropertyQuery.fulfilled, (state, action) => {
                var result = action.payload;
                if (result != -2) {
                    state.selectedPreDefined = PreDefinedList.None;
                    state.builderState = BuilderState.EditQuery; // After saving, turn to edit
                    state.saveButtonText = "Resave Query";
                    state.selectedQuery.propQueryID = result;
                    state.originalQuery = state.selectedQuery;
                    state.isChanged = false;
                }
                else {
                    state.isNameExisted = true;
                }
            })
            .addCase(postDeletePropertyQuery.fulfilled, (state, action) => {
                var result = action.payload;

                if (result != -2) {
                    state.builderState = BuilderState.PreDefinedQuery; // After deleting, turn to predefined query.

                    state.selectedPreDefined = PreDefinedList.PotentiallyThreatened;

                    state.selectedQuery = {
                        propQueryID: 0,
                        queryName: "",
                        fireId: state.selectedQuery.fireId,
                        clientIds: state.selectedQuery.clientIds,
                        propertyType: "",
                        city: "",
                        currentThreat: true,
                        lastPropertyStatus: ""      
                    };

                    state.passValidation = false;

                    state.saveButtonText = "Save Query";
                }
            })
            .addCase(getPropertyCitiesByFireClient.fulfilled, (state, action) => {
                state.propertyCities = action.payload;
            })
            .addCase(getAllPropertyType.fulfilled, (state, action) => {
                state.propertyTypes = action.payload;
            });
    }
});

export const { setBuilderState,
    setSelectedPreDefined,
    setPassValidation,
    setSelectedQuery,
    setOriginalQuery,
    setSaveButtonText,
    setIsNameExisted,
    setIsChanged } = scPropertyQuerySlice.actions;

export default scPropertyQuerySlice.reducer;