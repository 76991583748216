import { useEffect, useMemo } from "react";
import { CommandButton, DefaultButton, DirectionalHint, Icon, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";

import { INavigationGroup, Navigation } from "components/common/Navigation";
import logo from "assets/img/wds-logo-no-gloss-no-text.png";
import { useAppSelector, useAuthContext } from "app/hooks";
import { SIGNOUT_PATH } from "utils/consts";
import personLogo from "assets/img/person-logo.png";
import menuIcon from "assets/img/menu.png";
import { Constants } from "utils/constants";

export default function Header() {
    const { title } = useAppSelector(state => state.global);
    const { site, role, isInternalUser, clientIds, blobBaseURL } = useAuthContext();

    const person_logo = useMemo(() => {
        if (isInternalUser) {
            return personLogo;
        }
        else {
            var clientId = clientIds ? clientIds[0] : 0;
            return `${blobBaseURL}${Constants.Logo_Path}/${clientId?.toString()?.padStart(4, '0')}.png`;
        }
    }, [isInternalUser, blobBaseURL, clientIds]);
    const navLinkGroups: INavigationGroup[] = useMemo(() => {
        let groups: INavigationGroup[] = [];

        // Fire
        const fireItems = []
        if (Constants.AppRoles.allRead.includes(role)) {
            fireItems.push({ key: 'SitMap', text: 'Situation Map', href: '/sitmap' });
        }
        if (fireItems.length) {
            groups.push({ text: 'Fire', key: 'Fire', items: fireItems })
        }

        // Property        
        const propItems = []
        if (Constants.AppRoles.allRead.includes(role) && isInternalUser) {
            propItems.push({ key: 'PHA', text: 'PHA', href: '/pha' });
        }
        if (Constants.AppRoles.allRead.includes(role)) {
            propItems.push({ key: 'Property', text: 'Property Search', href: '/propertysearch' });
        }
        if (propItems.length) {
            groups.push({ text: 'Property', key: 'Property', items: propItems })
        }

        // Report        
        const reportItems = []
        if (Constants.AppRoles.allRead.includes(role)) {
            reportItems.push({ key: 'ProgramUse', text: 'Program Use', href: '/programUse' });
        }
        if (Constants.AppRoles.allRead.includes(role)) {
            reportItems.push({ key: 'HistoricFires', text: 'Historic Fires', href: '/historicFires' });
        }
        if (reportItems.length) {
            groups.push({ text: 'Report', key: 'Report', items: reportItems })
        }

        return groups;
    }, [site, role]);
    const menu: IContextualMenuProps = useMemo(() => {
        const items = (navLinkGroups || []).map<IContextualMenuItem>(group => ({
            text: group.text,
            key: group.key,
            subMenuProps: {
                items: group.items || []
            }
        }));
        items.push({ key: 'signout', text: 'Sign Out', href: SIGNOUT_PATH });

        return {
            items,
            directionalHint: DirectionalHint.bottomRightEdge
        };
    }, [navLinkGroups]);
    const tabletMenu = useMemo(() => {
        return <div className="mb-menu">
            <DefaultButton menuProps={menu} className="hamburger-button">
                <img src={menuIcon} />
            </DefaultButton>
        </div>;
    }, [menu])

    const { isAuthenticated, name, email } = useAuthContext();
    const actions: IContextualMenuProps = useMemo(() => ({
        items: [
            {
                key: 'profile',
                text: 'My Profile',
                href: '/profile'
            },
            {
                key: 'signout',
                text: 'Sign Out',
                onClick: () => {
                    window.location.href = SIGNOUT_PATH;
                }
            }
            
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    }), []);
    const userInfo = useMemo(() => {
        return isAuthenticated && email ? (
            <div className="person-logo">
                <Icon className="person-icon" imageProps={{ src: person_logo }} />
                <CommandButton className="user-action" text={name} menuProps={actions} title={name} />
            </div>
        ) : null;
    }, [isAuthenticated, person_logo, email, name, actions]);

    const onWDSLogoClick = () => {
        window.location.href = "/sitmap";
    }

    useEffect(() => {
        title && (document.title = title);
    }, [title]);
    return (
        <div className="header">
            <div className="bannertoprow d-flex">
                <div className="wdslogo" onClick={onWDSLogoClick}><img src={logo} alt="logo" /></div>
                <div className="wdstitle" onClick={onWDSLogoClick}>WILDFIRE DEFENSE SYSTEMS</div>
                <Navigation groups={navLinkGroups} />
                {userInfo}
                {tabletMenu}
            </div>
        </div>
    );
}